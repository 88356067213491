import { VpRoute } from '@zeiss/ng-vis-vp-auth/types';
import { ROUTE_PATHS } from './routes-paths';

export const ROUTE_CONFIG: VpRoute = {
	path: ROUTE_PATHS._,
	data: {
		title: 'external.logs.title',
		routes: [
			{
				path: ROUTE_PATHS.logs,
				data: {
					// empty string needed to not let the browser title update function
					// create duplicates (as there is no navboard on root)
					title: '',
					cache: true,
				},
			},
			{
				path: '**',
				redirectTo: ROUTE_PATHS.logs,
			},
		],
	},
};
